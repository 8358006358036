var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('gx-bc'),_c('gx-rpf',{attrs:{"startDateType":"date","endDateType":"date","includeSeller":true,"includeProviders":true,"includeAgents":false,"includeChannels":false,"includeProducts":false,"dateColumnName":"PurchaseDate","channelColumnName":"SalesChannel","providerColumnName":"ProviderName","summaryColumnOne":"Total Sales: $","summaryColumnTwo":"","summaryColumnThree":""},on:{"submit":_vm.onViewReport}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{ref:"card",staticClass:"pl-0 pt-0 pr-0 pb-1"},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"dense":"","flat":"","color":"grey lighten-3"}},[_c('v-toolbar-title',[_vm._v("Results")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":_vm.onExportToExcel}},on),[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g({on:{"click":_vm.onShowHideColumns}},on),[_vm._v("mdi-table-edit")])],1)]}}])},[_c('span',[_vm._v("Show/Hide Columns")])])],1),_c('dx-data-grid',{ref:"grid",staticClass:"px-2 py-1",staticStyle:{"max-height":"500px"},attrs:{"id":"gridContainer","show-borders":true,"data-source":_vm.dataSource,"allow-column-reordering":true,"allow-column-resizing":true,"column-auto-width":true,"columns":_vm.columns,"remote-operations":{
          groupPaging: true,
          paging: true,
          sorting: true,
          grouping: true,
        },"grouping":{ autoExpandAll: false },"columnFixing":{ enabled: true },"columnChooser":{ enabled: false, mode: 'select' },"headerFilter":{ visible: false },"filterRow":{ visible: true },"groupPanel":{ visible: true },"paging":{ pageSize: 5000 },"pager":{
          showPageSizeSelector: true,
          allowedPageSizes: [50, 100, 500],
          showInfo: true,
        },"rowAlternationEnabled":true},on:{"content-ready":_vm.onContentReady}},[_c('DxSummary',[_c('DxTotalItem',{attrs:{"column":"RowCount","summary-type":"sum","display-format":"Total: {0}"}}),_c('DxTotalItem',{attrs:{"column":"ExchangePrice","summary-type":"sum","value-format":"$#0.00","display-format":"Total: {0}"}}),_c('DxTotalItem',{attrs:{"column":"SalesPrice","summary-type":"sum","value-format":"$#0.00","display-format":"Total: {0}"}}),_c('DxTotalItem',{attrs:{"column":"Tax","summary-type":"sum","value-format":"$#0.00","display-format":"Total: {0}"}}),_c('DxTotalItem',{attrs:{"column":"ExchangePromoAdjustmentAmount","summary-type":"sum","value-format":"$#0.00","display-format":"Total: {0}"}}),_c('DxTotalItem',{attrs:{"column":"CommissionToSeller","summary-type":"sum","value-format":"$#0.00","display-format":"Total: {0}"}}),_c('DxTotalItem',{attrs:{"column":"TotalSettlementAmount","summary-type":"sum","value-format":"$#0.00","display-format":"Total: {0}"}})],1),_c('dx-export',{attrs:{"enabled":false,"allow-export-selected-data":true,"file-name":_vm.exportFileName}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }